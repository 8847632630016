import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidReference } from "mobx-state-tree";
import RequestComment from "../calendar/controls/requestComment";
import Color from "color";
import { observer, inject } from "mobx-react";
import moment from "moment";
import ConfirmButton from "../calendar/controls/confirmButton";
import { Table, Icon, Popup as SemanticPopup, Button } from "semantic-ui-react";

const threesort = (a, b) => {
	const x = a[1].localeCompare(b[1]);
	if (x !== 0) return x;
	return a[2].localeCompare(b[2]);
};

function DayViewJob({
	app,
	jobId,
	truckCategories,
	planRes,
	polierCategories,
	startResourceEditor,
	startJobEditModal,
	showNumberHumans,
	showNameHumans,
	showNumberTrucks,
	showNameTrucks,
	showNumberMachines,
	showNameMachines,
	handleResClick,
	hasTrucks,
	widths,
}) {
	//TODO: USE MEMO TO CALCULATE EVERYTHING!

	const [opened, toggleHeight] = useState(false);
	const { t } = useTranslation();
	const orders = [];
	const disposition = [];
	const humans = [];
	const trucks = [];
	const contacts = [];
	const machines = [];

	const job = app.projects.jobs.get(jobId);

	for (let contact of job.project.$contacts.values()) {
		if (!isValidReference(() => contact) || contact.deleted) continue;
		contacts.push([
			<div key={contact.id}>
				{contact.function.length
					? t("detailBox.contact", {
							name: contact.person.name,
							function: contact.function,
					  })
					: contact.person.name}
			</div>,
			contact.person.name,
			"",
		]);
	}

	for (let deployment of job.$deployments.values()) {
		if (deployment.deleted || !deployment.resource) continue;

		const type = deployment.resource.resclass.human
			? "human"
			: truckCategories.includes(deployment.resource.resclass.id)
			? "truck"
			: "machine";

		const showNumber =
			(type === "human" && showNumberHumans) ||
			(type === "truck" && showNumberTrucks) ||
			(type === "machine" && showNumberMachines);

		const showName =
			(type === "human" && showNameHumans) ||
			(type === "truck" && showNameTrucks) ||
			(type === "machine" && showNameMachines);

		const name = [];
		if (showNumber && deployment.resource.inventaryNumber !== "")
			name.push(deployment.resource.inventaryNumber);
		if (showName && deployment.resource.name !== "")
			name.push(deployment.resource.name);

		const absences = Object.values(
			deployment.resource.getAbsencesByDates([
				moment(job.start).format("YYYY-MM-DD"),
			])
		);


		const label = (
			<div
				className={
					"resourceLabel" +
					(deployment.additional !== "" ? " hasComment" : "") +
					(absences.length > 0 ? " hasAbsence" : "")
				}
				title={absences.length > 0 ? absences[0][0].type.name : null}
				style={
					absences.length > 0
						? {
								backgroundColor: absences[0][0].type.color,
								color: (new Color(absences[0][0].type.color)).isLight() ? "black" : "white"
						  }
						: null
				}
			>
				<div
					className="labelImage"
					style={{
						backgroundImage: "url(" + deployment.resource.imageUrl + ")",
					}}
				/>
				<div className="labelText">{name.join(" - ")}</div>
				{deployment.additional !== "" ? (
					<div className="labelAddText">{deployment.additional}</div>
				) : null}
				{planRes ? (
					<ConfirmButton
						onClick={deployment.delete}
						trigger={<Icon name="trash alternate outline" />}
					/>
				) : null}
			</div>
		);
		const reqComm = [
			planRes ? (
				<RequestComment
					onSave={deployment.setAdditional}
					text={deployment.additional}
					trigger={label}
				/>
			) : (
				label
			),
			polierCategories.includes(deployment.resource.resclass.id)
				? "AAAAAA"
				: deployment.additional === ""
				? "ZZZZ1"
				: deployment.additional,
			deployment.resource.name,
		];
		if (type === "human") {
			humans.push(reqComm);
		} else if (type === "truck") {
			trucks.push(reqComm);
		} else {
			machines.push(reqComm);
		}
	}

	for (let deployment of job.$rentalDeployments.values()) {
		if (deployment.deleted) continue;
		if (!deployment.rentalResource.resclass) continue;

		const type = deployment.rentalResource.resclass.human
			? "human"
			: truckCategories.includes(deployment.rentalResource.resclass.id)
			? "truck"
			: "machine";

		const showName =
			(type === "human" && showNameHumans) ||
			(type === "truck" && showNameTrucks) ||
			(type === "machine" && showNameMachines);

		const name = [];
		if (showName && deployment.rentalResource.name !== "")
			name.push(deployment.rentalResource.name);

		const label = (
			<div className={"resourceLabel"}>
				<div className="labelImage rentalResource">
					{t("resourceChooser.rent").substr(0, 1)}
				</div>
				<div className="labelText">{name.join(" - ")}</div>

				{planRes ? (
					<ConfirmButton
						onClick={deployment.delete}
						trigger={<Icon name="trash alternate outline" />}
					/>
				) : null}
			</div>
		);
		const reqComm = [
			label,
			polierCategories.includes(deployment.rentalResource.resclass.id)
				? "AAAAAA"
				: "ZZZZ1",
			deployment.rentalResource.name,
		];
		if (type === "human") {
			humans.push(reqComm);
		} else if (type === "truck") {
			trucks.push(reqComm);
		} else {
			machines.push(reqComm);
		}
	}

	for (let i = 0; trucks.length && i < machines.length; i++) {
		if (machines[i][1] === "ZZZZ1") continue;
		const searchfor = machines[i][1].toLowerCase().replace(/[^a-z0-9]/g, "");
		for (let truck of trucks) {
			if (truck[1] === "ZZZZ1") continue;
			if (
				truck[2]
					.toLowerCase()
					.replace(/[^a-z0-9]/g, "")
					.includes(searchfor)
			) {
				machines[i][1] = "AAAAAA-" + truck[1];
				break;
			}
		}
	}

	for (let request of job.openRequests) {
		let label = (
			<div
				className={
					"resourceLabel request" +
					(request.comment !== "" ? " hasComment" : "")
				}
				onDoubleClick={
					planRes
						? (e) =>
								handleResClick(
									e,
									job.project.id,
									job.process.id,
									moment(job.start).format("YYYY-MM-DD"),
									request.requirement,
									true
								)
						: null
				}
			>
				<div
					className="labelImage"
					style={{
						backgroundImage: "url(" + request.requirement.imageUrl + ")",
					}}
				/>
				<div className="labelText">{request.requirement.name}</div>
				{planRes ? (
					<ConfirmButton
						onClick={request.decrement}
						trigger={<Icon name="trash alternate outline" />}
					/>
				) : null}
			</div>
		);
		if (request.comment !== "") {
			label = <SemanticPopup content={request.comment} trigger={label} />;
		}
		if (request.requirement.resclass.human) {
			humans.push([label, "ZZZZ2", request.requirement.name]);
		} else if (truckCategories.includes(request.requirement.resclass.id)) {
			trucks.push([label, "ZZZZ2", request.requirement.name]);
		} else {
			machines.push([label, "ZZZZ2", request.requirement.name]);
		}
	}

	for (let order of job.$orders.values()) {
		if (order.deleted) continue;
		orders.push(
			<div>
				{t("detailBox.order", {
					material: order.material.safeShortname,
					supplier: order.supplier.safeShortname,
					unit: order.material.unit,
					amount: order.amount,
				}).replace(/ /g, "\u00A0")}
			</div>
		);
	}

	for (let dispo of job.$disposition.values()) {
		if (dispo.deleted) continue;
		disposition.push(
			<div>
				{t("detailBox.disposition", {
					type: dispo.type.name,
					supplier: dispo.supplier.safeShortname,
					amount: dispo.amount,
				}).replace(/ /g, "\u00A0")}
			</div>
		);
	}

	const bcolor = new Color(job.process.template.color);

	return (
		<Table.Row className={opened ? "heightLimit" : "heightLimit closed"}>
			<Table.Cell
				textAlign="center"
				style={{
					backgroundColor: job.process.template.color,
					color: bcolor.isLight() ? "black" : "white",
				}}
			>
				<div
					className="heightLimited hasGradient heightTrigger"
					onClick={() => toggleHeight(!opened)}
					style={
						widths.process
							? {
									minWidth: widths.process,
									maxWidth: widths.process,
									width: widths.process,
							  }
							: null
					}
				>
					<Icon
						name={"picon-" + job.process.template.icon}
						className="processIcon"
					/>
					<div className="processIconName">{job.process.name}</div>
					<div
						className="gradient"
						style={{
							background:
								"linear-gradient(0deg, " +
								job.process.template.color +
								" 0%, " +
								bcolor.alpha(0).string() +
								" 100%)",
						}}
					/>
				</div>
			</Table.Cell>
			<Table.Cell
				verticalAlign="middle"
				textAlign="center"
			>
				<div
					className="heightLimited"
					style={
						widths.lock
							? {
									minWidth: widths.lock,
									maxWidth: widths.lock,
									width: widths.lock,
							  }
							: null
					}
				>
					<Button
										onClick={() => job.project.setFixed(!job.project.fixed)}
										icon={job.project.fixed ? "lock" : "lock open"}
										disabled={!job.project.editable}
										active={job.project.fixed}
									
									/>
				</div>
			</Table.Cell>
			<Table.Cell>
				<div
					className="heightLimited"
					style={
						widths.project
							? {
									minWidth: widths.project,
									maxWidth: widths.project,
									width: widths.project,
							  }
							: null
					}
				>
					<div className="dl_jobname">{job.project.fullName}</div>
					{job.project.client.length ? (
						<div>
							{job.project.client.split("\n").map((item, key) => {
								return (
									<React.Fragment key={key}>
										{item}
										<br />
									</React.Fragment>
								);
							})}
						</div>
					) : null}
					{job.project.comment.length ? (
						<div>
							{job.project.comment.split("\n").map((item, key) => {
								return (
									<React.Fragment key={key}>
										{item}
										<br />
									</React.Fragment>
								);
							})}
						</div>
					) : null}
					{job.comment.length ? (
						<div>
							{job.comment.split("\n").map((item, key) => {
								return (
									<React.Fragment key={key}>
										{item}
										<br />
									</React.Fragment>
								);
							})}
						</div>
					) : null}
				</div>
			</Table.Cell>
			<Table.Cell>
				<div
					className="heightLimited"
					style={
						widths.contact
							? {
									minWidth: widths.contact,
									maxWidth: widths.contact,
									width: widths.contact,
							  }
							: null
					}
				>
					{contacts.length ? (
						<div className="labelContainer">
							{contacts.sort(threesort).map((x) => x[0])}
						</div>
					) : (
						<div className="nothingPlaceholder">
							<Icon name="times" />
						</div>
					)}
				</div>
			</Table.Cell>
			<Table.Cell>
				<div
					className="heightLimited"
					style={
						widths.time
							? {
									minWidth: widths.time,
									maxWidth: widths.time,
									width: widths.time,
							  }
							: null
					}
				>
					{moment(job.start).format("HH:mm")}&ndash;
					{moment(job.end).format("HH:mm")}
					<br />
					{moment
						.duration(moment(job.end).diff(moment(job.start)))
						.humanize({ m: 90 })
						.replace(/ /g, "\u00A0")}
				</div>
			</Table.Cell>
			<Table.Cell verticalAlign={orders.length ? "top" : "middle"}>
				<div
					className="heightLimited"
					style={
						widths.material
							? {
									minWidth: widths.material,
									maxWidth: widths.material,
									width: widths.material,
							  }
							: null
					}
				>
					{orders.length ? (
						orders
					) : (
						<div className="nothingPlaceholder">
							<Icon name="times" />
						</div>
					)}
				</div>
			</Table.Cell>
			{!hasTrucks ? (
				<Table.Cell verticalAlign={disposition.length ? "top" : "middle"}>
					<div
						className="heightLimited"
						style={
							widths.trucks
								? {
										minWidth: widths.trucks,
										maxWidth: widths.trucks,
										width: widths.trucks,
								  }
								: null
						}
					>
						{disposition.length ? (
							disposition
						) : (
							<div className="nothingPlaceholder">
								<Icon name="times" />
							</div>
						)}
					</div>
				</Table.Cell>
			) : null}
			<Table.Cell verticalAlign={humans.length ? "top" : "middle"}>
				<div
					className="heightLimited"
					style={
						widths.humans
							? {
									minWidth: widths.humans,
									maxWidth: widths.humans,
									width: widths.humans,
							  }
							: null
					}
				>
					{humans.length ? (
						<div className="labelContainer">
							{humans.sort(threesort).map((x) => x[0])}
						</div>
					) : (
						<div className="nothingPlaceholder">
							<Icon name="times" />
						</div>
					)}
				</div>
			</Table.Cell>
			{hasTrucks ? (
				<Table.Cell collapsing verticalAlign={trucks.length ? "top" : "middle"}>
					<div style={{ position: "relative" }}>
						{trucks.length ? (
							<div className="hxTruckCount">{trucks.length}</div>
						) : null}
						<div
							className="heightLimited"
							style={
								widths.trucks
									? {
											minWidth: widths.trucks,
											maxWidth: widths.trucks,
											width: widths.trucks,
									  }
									: null
							}
						>
							{trucks.length ? (
								<div className="labelContainer">
									{trucks.sort(threesort).map((x) => x[0])}
								</div>
							) : (
								<div className="nothingPlaceholder">
									<Icon name="times" />
								</div>
							)}
						</div>
					</div>
				</Table.Cell>
			) : null}
			<Table.Cell
				collapsing={hasTrucks}
				verticalAlign={machines.length ? "top" : "middle"}
			>
				<div
					className="heightLimited"
					style={
						widths.machines
							? {
									minWidth: widths.machines,
									maxWidth: widths.machines,
									width: widths.machines,
							  }
							: null
					}
				>
					{machines.length ? (
						<div className="labelContainer">
							{machines.sort(threesort).map((x) => x[0])}
						</div>
					) : (
						<div className="nothingPlaceholder">
							<Icon name="times" />
						</div>
					)}
				</div>
			</Table.Cell>
			<Table.Cell verticalAlign="middle" collapsing className="padded">
				{job.project.editable ? (
					<Button icon="pencil" onClick={() => startJobEditModal(job)} />
				) : null}
				{planRes ? (
					<Button
						icon="picon-excavator"
						onClick={() => startResourceEditor([job])}
					/>
				) : null}
			</Table.Cell>
		</Table.Row>
	);
}

export default inject("app")(observer(DayViewJob));
