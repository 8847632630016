import React from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import moment from "moment";
import ResourceLineBoxes from "./resourceLineBoxes";
import WhiteMarkers from "./whiteMarkers";

class ResourceLine extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dragMode: false
		};
		this.whiteClick = this.whiteClick.bind(this);
		this.getX = this.getX.bind(this);
	}

	setDragMode(dragMode){
		this.setState({dragMode});
	}

	whiteClick(e) {
		let left;
		if (e.target.classList.contains("standard_box")){
			left = e.clientX - e.target.parentNode.getBoundingClientRect().x;
		} else if (!e.target.classList.contains("resource_body")) return false;
		else left = e.clientX - e.target.getBoundingClientRect().x;
		this.props.app.ui.whiteClick(
			e.timeStamp,
			moment(
				new Date(
					(this.props.app.ui.calDuration * left) / this.props.xwidth +
						this.props.app.ui.view.start.getTime()
				)
			).format("YYYY-MM-DD"),
			this.props.resource
		);
	}

	getX(date) {
		return (
			((date.getTime() - this.props.app.ui.view.start.getTime()) /
				this.props.app.ui.calDuration) *
			this.props.xwidth
		);
	}

	render() {
		const r = this.props.resource;
		console.time("resline2 "+r.name);
		const isGroupLine = this.props.group === true;
		const width =
			((this.props.app.ui.view.end - this.props.app.ui.view.start) /
				this.props.app.ui.calDuration) *
			this.props.xwidth;
		const lineHeight = isGroupLine ? 20 : 30;
		const ret = (
			<tr key={"tr" + r.id} className={"resource_main"+(isGroupLine ? " groupline": "")}>
				<td
					className="resource_head sticky"
					style={{
						cursor: "pointer",
						left: 0,
						zIndex: 30,
						verticalAlign: "top"
					}}
				>
					<div
						className={"resource_name"+(this.props.app.ui.modules.has("LARGER_PROJECTNAMES") ? " extended" : "")}
						style={{
							whiteSpace: "nowrap",
							height: lineHeight
						}}
						onClick={
							("group" in this.props) && this.props.group !== true ? this.props.group : null
						}
					>
						{isGroupLine ? null : (<div
							className={"imageInner"+(("group" in this.props) && this.props.group !== true ? " iiGroup picon picon-group" : "")}
							style={("group" in this.props) && this.props.group !== true ? null : {
								backgroundImage: "url(" + r.imageUrl + ")"
							}}
						/>)}
						<div className="nameInner">{r.name}</div>
						{isGroupLine || !(this.props.app.ui.modules.has("RESOURCE_ANALYSIS") && this.props.app.ui.rights.has("RESOURCE_ANALYSIS")) ? null : (<div className="buttonInner">
							<Button
								as={Link}
								to={
									"/report/" +
									r.id
								}
								icon="chart pie"
								size="mini"
								compact
							/>
						</div>)}
					</div>
				</td>
				<td>
					<div
						className="resource_body resdroptarget"
						data-resource={r.id}
						onClick={this.props.app.ui.rights.has("PLAN_RESOURCES") ? this.whiteClick : null}
						style={{
							zIndex: this.state.dragMode ? 20: 2,
							width: width,
							height: lineHeight,
							position: "relative"
						}}
					>
						<ResourceLineBoxes
							resource={r}
							height={lineHeight}
							xwidth={this.props.xwidth}
							dragMode={this.state.dragMode}
							setDragMode={d => this.setDragMode(d)}
						/>
						{this.props.app.ui.whiteClickData.resource.id === r.id ? (
							<WhiteMarkers
								hide={this.state.dragMode}
								getX={this.getX}
								boxHeight={lineHeight}
								days={this.props.app.ui.whiteClickData.selected}
							/>
						) : null}
					</div>
				</td>
			</tr>
		);
		console.timeEnd("resline2 "+r.name);
		return ret;
	}
}

export default inject("app")(withTranslation()(observer(ResourceLine)));
