import React from "react";
import { observer, inject } from "mobx-react";
import { arrayFilter } from "../../../helpers/calendar";
import ProjectLine from "./projectLine";
import ProjectEditLine from "./projectEditLine";

const ProjectLines = ({ app: { ui, projects }, width, edit, calendar }) => {

	const filter = arrayFilter(ui.filterString);

	return (
		<React.Fragment>
			{edit ? (
				<ProjectEditLine xwidth={width} edit={edit} />
			) : (
				projects
					.getProjectsBetween(ui.paddedView)
					.filter(x => filter([x.name, x.bstn, x.agfFilterString]))
					.map((p, i) => (
						<ProjectLine
							calendar={calendar}
							key={p.id}
							xwidth={width}
							project={p}
							position={i}
						/>
					))
			)}
		</React.Fragment>
	);
};

export default inject("app")(observer(ProjectLines));
