import {
	types,
	tryReference,
	getRoot,
	getSnapshot,
	isValidReference,
} from "mobx-state-tree";
import pick from "lodash.pick";
import omit from "lodash.omit";
import uuid from "uuid";
import moment from "moment";
import { ProcessTemplate, Person, ProjectPosition, Supplier } from "./basedata";
import { Order, Disposition, Deployment } from "./orders";
import { Requirement, ResClass } from "./resources";
import { strToDate } from "../helpers/calendar";
import omitBy from "lodash.omitby";

/**
 * Mobx State Tree Store
 * The store recieves 3 parameters
 *  1st one is the Store Name
 *  2nd is an object with the Props and Computed values
 *  3rd is and object with the Actions
 **/

export const Request = types
	.model("Request", {
		id: types.identifier,
		_requirement: types.maybeNull(
			types.late(() => types.reference(Requirement))
		),
		deleted: types.optional(types.boolean, false),
		count: types.number,
		comment: types.string,
		_job: types.reference(types.late(() => ProjectJob)),
		_project: types.reference(types.late(() => Project)),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			q.requirement = q._requirement;
			return pick(q, [
				"id",
				"count",
				"job",
				"requirement",
				"deleted",
				"updatedAt",
				"comment",
			]);
		},
		get process() {
			if (!isValidReference(() => self._job)) return null;
			if (!isValidReference(() => self._job._process)) return null;
			return self._job._process;
		},
		get job() {
			if (isValidReference(() => self._job)) return self._job;
			return null;
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		},
		get requirement() {
			if (isValidReference(() => self._requirement)) return self._requirement;
			return null;
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			try {
				self._job.registerRequest(self.id);
			} catch (e) {}
		},
		decrement() {
			if (self.count > 1) {
				self.count -= 1;
				self.updatedAt = new Date();
			} else {
				self.delete();
			}
		},
		delete() {
			self.deleted = true;
			self.updatedAt = new Date();
		},
	}));

const RentalResource = types
	.model("RentalResource", {
		id: types.identifier,
		_project: types.reference(types.late(() => Project)),
		_resclass: types.reference(types.late(() => ResClass)),
		_supplier: types.maybeNull(types.reference(types.late(() => Supplier))),
		$rentalDeployments: types.map(
			types.reference(types.late(() => RentalDeployment))
		),
		name: types.string,
		comment: types.string,
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get resclass() {
			if (isValidReference(() => self._resclass)) return self._resclass;
			return null;
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		},
		get supplier() {
			if (isValidReference(() => self._supplier)) return self._supplier;
			return { name: "?", id: null };
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			try {
				self._project.registerRentalResource(self.id);
			} catch (e) {}
		},
		registerRentalDeployment(id) {
			self.$rentalDeployments.set(id, id);
		},
		update(name, supplier) {
			self.name = name;
			try {
				self._supplier = supplier;
			} catch (e) {
				self._supplier = null;
			}
			self.updatedAt = new Date();
		},
	}));

const RentalDeployment = types
	.model("RentalDeployment", {
		id: types.identifier,
		_project: types.reference(types.late(() => Project)),
		_job: types.reference(types.late(() => ProjectJob)),
		_rentalResource: types.reference(types.late(() => RentalResource)),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get job() {
			if (isValidReference(() => self._job)) return self._job;
			return { id: "" };
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		},
		get rentalResource() {
			if (isValidReference(() => self._rentalResource))
				return self._rentalResource;
			return { id: "" };
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			try {
				self._job.registerRentalDeployment(self.id);
				self._rentalResource.registerRentalDeployment(self.id);
			} catch (e) {}
		},
		delete() {
			self.deleted = true;
			self.updatedAt = new Date();
		},
	}));

const ProjectAttachment = types
	.model("ProjectAttachment", {
		id: types.identifier,
		_project: types.reference(types.late(() => Project)),
		creationTime: types.optional(types.Date, () => new Date()),
    creator: types.string,
    user: types.string,
    description: types.string,
    attachment: types.maybeNull(types.string),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			return pick(q, [
				"id",
				"creationTime",
				"creator",
				"user",
				"description",
				"attachment",
				"deleted",
				"updatedAt",
				"readOnly",
			]);
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		}
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			try {
				self._project.registerProjectAttachment(self.id);
			} catch (e) {}
		},
	}));

const Contact = types
	.model("Contact", {
		id: types.identifier,
		function: types.string,
		_project: types.reference(types.late(() => Project)),
		_person: types.maybeNull(types.reference(Person)),
		readOnly: types.optional(types.boolean, true),
		deleted: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			q.person = q._person;
			return pick(q, [
				"id",
				"function",
				"person",
				"deleted",
				"updatedAt",
				"readOnly",
			]);
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		},
		get person() {
			if (isValidReference(() => self._person)) return self._person;
			return { name: "?" };
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			try {
				self._project.registerContact(self.id);
			} catch (e) {}
		},
	}));

export const ProjectJob = types
	.model("ProjectJob", {
		id: types.identifier,
		start: types.Date,
		end: types.Date,
		comment: types.optional(types.string, ""),
		truckTonnage: types.maybeNull(types.number),
		bpo: types.optional(types.string, ""),
		deleted: types.optional(types.boolean, false),
		$orders: types.map(types.reference(Order)),
		$deployments: types.map(types.late(() => types.reference(Deployment))),
		_process: types.reference(types.late(() => ProjectProcess)),
		_project: types.reference(types.late(() => Project)),
		$requests: types.map(types.reference(Request)),
		$rentalDeployments: types.map(types.reference(RentalDeployment)),
		$disposition: types.map(types.reference(Disposition)),
		position: types.optional(types.maybeNull(ProjectPosition), {
			lat: 0,
			lng: 0,
			zoom: 0,
		}),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get process() {
			if (isValidReference(() => self._process)) return self._process;
			return null;
		},
		get safePosition() {
			if (self.position && self.position.lat !== 0) return self.position;
			if (self.project) return self.project.position;
			return null;
		},
		get project() {
			if (isValidReference(() => self._project)) return self._project;
			return null;
		},
		get isNight() {
			const start = self.start.getHours();
			const end = self.end.getHours();
			if (end < start || start > 20 || start < 6 || end > 20 || end < 6)
				return true;
			return false;
		},
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			//get orders, and disposition as array
			q.orders = Object.values(q.$orders);
			q.disposition = Object.values(q.$disposition);
			//get contacts and requests as copy
			q.requests = Object.values(q.$requests);

			return pick(q, [
				"id",
				"start",
				"end",
				"name",
				"disposition",
				"orders",
				"bpo",
				"requests",
				"comment",
				"deleted",
				"truckTonnage",
				"position",
				"updatedAt",
			]);
		},
		get openRequests() {
			const slots = [];
			//get possible resources for all requests
			for (let request of self.$requests.values()) {
				if (
					!isValidReference(() => request) ||
					request.deleted ||
					!isValidReference(() => request._requirement) ||
					request._requirement.deleted ||
					!isValidReference(() => request._requirement._resclass) ||
					request._requirement._resclass.deleted
				)
					continue;
				const req = request._requirement;

				const possible = Array.from(self.$deployments.values())
					.filter((d) => {
						if (!isValidReference(() => d) || d.deleted) return false;
						if (req.satisfiedBy === null) {
							if (!isValidReference(() => d._resource)) return false;
							if (!isValidReference(() => d._resource._resclass)) return false;
							if (!isValidReference(() => req._resclass)) return false;
							if (d._resource._resclass.id === req._resclass.id){
								return true;
							}
							return req.additionalClasses.includes(d._resource._resclass.id);
						} else {
							if (!isValidReference(() => d._resource)) return false;
							return req.satisfiedBy.includes(d._resource.id);
						}
					})
					.concat(
						Array.from(self.$rentalDeployments.values()).filter((d) => {
							if (!isValidReference(() => d) || d.deleted) return false;
							if (!isValidReference(() => d._rentalResource)) return false;
							if (!isValidReference(() => d._rentalResource._resclass))
								return false;
							if (!isValidReference(() => req._resclass)) return false;
							return d._rentalResource._resclass.id === req._resclass.id;
						})
					);
				for (let i = 0; i < request.count; i++) slots.push([request, possible]);
			}

			slots.sort((a, b) =>
				a[1].length < b[1].length ? -1 : a[1].length > b[1].length ? 1 : 0
			);
			const usedDeployments = new Set();
			const openRequests = [];
			outer: for (let [request, possible] of slots) {
				if (
					usedDeployments.size >=
					self.$deployments.size + self.$rentalDeployments.size
				) {
					openRequests.push(request);
					//	console.log("all deployments are used");
					continue;
				}
				for (let deployment of possible) {
					if (!usedDeployments.has(deployment.id)) {
						usedDeployments.add(deployment.id);

						continue outer;
					}
				}

				openRequests.push(request);
			}

			return openRequests;
		},
		get resources() {
			const res = new Map();
			for (let deployment of self.$deployments.values()) {
				if (
					!isValidReference(() => deployment) ||
					deployment.deleted ||
					!isValidReference(() => deployment._resource)
				)
					continue;
				res.set(deployment._resource.id, deployment);
			}
			return res;
		},
		mapList(domain) {
			const outMap = new Map();
			for (let xRef of self[domain].values()) {
				try {
					let ref = tryReference(() => xRef);
					if (typeof ref === "undefined") continue;
					outMap.set(ref.id, ref.oldFormat());
				} catch (e) {
					console.error(e);
				}
			}
			return outMap;
		},
		hasResource(resid) {
			return self.resources.has(resid);
		},
		hasGroup(groupId) {
			for (let deployment of self.resources.values()) {
				if (
					!isValidReference(() => deployment) ||
					deployment.deleted ||
					!deployment._resGroup ||
					!isValidReference(() => deployment._resGroup)
				)
					continue;
				if (deployment._resGroup.id === groupId) return true;
			}
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			self._process.registerJob(self.id);
		},
		registerRentalDeployment(id) {
			self.$rentalDeployments.set(id, id);
		},
		registerOrder(id) {
			self.$orders.set(id, id);
		},
		registerDisposition(id) {
			self.$disposition.set(id, id);
		},
		registerRequest(id) {
			self.$requests.set(id, id);
		},
		registerDeployment(id) {
			self.$deployments.set(id, id);
		},
		removeRentalResources(idlist) {
			for (let rentalDeployment of self.$rentalDeployments.values()) {
				if (
					!isValidReference(() => rentalDeployment) ||
					rentalDeployment.deleted
				)
					continue;
				if (idlist.has(rentalDeployment.rentalResource.id));
				rentalDeployment.delete();
			}
		},
		move(diff) {
			self.start = new Date(self.start.getTime() + diff);
			self.end = new Date(self.end.getTime() + diff);
			self.updatedAt = new Date();
			getRoot(self).resources.triggerCollisionRecalculation();
		},
		updateRequests(targetRequests) {
			const done = new Set();
			for (let request of self.$requests.values()) {
				if (!isValidReference(() => request) || request.deleted) continue;
				const reqId = request.requirement ? request.requirement.id : "INVALID";
				if (!(reqId in targetRequests) || done.has(reqId)) {
					request.delete();
					continue;
				}
				const target = targetRequests[reqId];
				if (target.count) {
					request.count = target.count;
					request.updatedAt = new Date();
					done.add(reqId);
				}
				if (target.comment !== false) {
					request.comment = target.comment;
					request.updatedAt = new Date();
				}
			}
			const add = Object.keys(targetRequests).filter((x) => !done.has(x));
			const newRequests = [];
			for (let reqId of add) {
				const target = targetRequests[reqId];
				if (!target.count) continue;
				newRequests.push({
					id: uuid.v4(),
					_requirement: reqId,
					count: target.count,
					comment: target.comment !== false ? target.comment : "",
					_job: self.id,
					_project: self.project.id,
				});
			}
			if (newRequests.length) {
				getRoot(self).projects.updateRequests(newRequests);
			}
		},
		dispose(resource, operation, group = null) {
			if (operation === "ADD") {
				if (self.resources.has(resource.id)) {
					if (!group) {
						return false;
					}

					const ref = self.resources.get(resource.id);
					if (
						!isValidReference(() => ref) ||
						!isValidReference(() => ref._resGroup) ||
						ref._resGroup.id === group
					) {
						return false;
					}

					ref.delete();
				}
				if (!isValidReference(() => self._project)) return false;
				const deploymentId = uuid.v4();

				getRoot(self).orders.updateDeployments([
					{
						id: deploymentId,
						_resource: resource.id,
						_job: self.id,
						_project: self._project.id,
						_resGroup: group,
					},
				]);
			} else if (operation === "REMOVE") {
				if (!self.resources.has(resource.id)) return false;
				const ref = self.resources.get(resource.id);
				if (!isValidReference(() => ref)) return false;
				if (
					group &&
					isValidReference(() => ref._resGroup) &&
					ref._resGroup.id !== group
				)
					return false;
				ref.delete();
			}
			getRoot(self).resources.triggerCollisionRecalculation();
		},
	}));

const ProjectProcess = types
	.model("ProjectProcess", {
		id: types.identifier,
		_template: types.reference(ProcessTemplate),
		name: types.string,
		$jobs: types.map(types.reference(ProjectJob)),
		_project: types.reference(types.late(() => Project)),
		bstn: types.optional(types.string, ""),
		deleted: types.optional(types.boolean, false),
		hasLite: types.optional(types.boolean, false),
		updatedAt: types.optional(types.Date, () => new Date()),
	})
	.views((self) => ({
		get template() {
			if (isValidReference(() => self._template)) return self._template;
			return { color: "black", icon: "x question circle", name: "?" };
		},
		get project() {
			if (!isValidReference(() => self._project)) return null;
			return self._project;
		},
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			//get orders, and disposition as array
			q.jobs = {};
			q.template = q._template;
			for (let job of self.$jobs.values()) {
				if (!isValidReference(() => job)) continue;
				q.jobs[job.id] = job.oldFormat();
			}
			return pick(q, [
				"id",
				"name",
				"template",
				"jobs",
				"deleted",
				"updatedAt",
				"hasLite",
				"bstn"
			]);
		},
		jobMapList(domain) {
			let mp = new Map();
			for (let job of self.$jobs.values()) {
				if (!isValidReference(() => job)) continue;
				/* eslint-disable no-loop-func */
				mp = new Map(
					(function*() {
						yield* mp;
						yield* job.mapList(domain);
					})()
				);
				/* eslint-enable no-loop-func */
			}
			return mp;
		},
		get start() {
			let out = null;
			for (let p of self.$jobs.values()) {
				if (!isValidReference(() => p) || p.deleted) continue;
				if (p.start !== null && (out === null || out > p.start)) out = p.start;
			}
			return out;
		},
		get end() {
			let out = null;
			for (let p of self.$jobs.values()) {
				if (!isValidReference(() => p) || p.deleted) continue;
				if (p.end !== null && (out === null || out < p.start)) out = p.end;
			}
			return out;
		},
	}))
	.actions((self) => ({
		registerReferences() {
			//TODO
			self._project.registerProcess(self.id);
		},
		registerJob(id) {
			self.$jobs.set(id, id);
		},
	}));

export const Project = types
	.model("Project", {
		id: types.identifier,
		name: types.string,
		$processes: types.map(types.reference(ProjectProcess)),
		comment: types.optional(types.string, ""),
		client: types.optional(types.string, ""),
		$contacts: types.map(types.reference(Contact)),
		deleted: types.optional(types.boolean, false),
		bstn: types.optional(types.string, ""),
		costbase: types.optional(types.string, ""),
		agfData: types.frozen({}),
		position: types.optional(types.maybeNull(ProjectPosition), {
			lat: 0,
			lng: 0,
			zoom: 0,
		}),
		updatedAt: types.optional(types.Date, () => new Date()),
		$rentalResources: types.map(types.reference(RentalResource)),
		$projectAttachments: types.map(types.reference(ProjectAttachment)),
		username: types.string,
		moveable: types.optional(types.boolean, false),
		fixed: types.optional(types.boolean, false),
		editable: types.optional(types.boolean, false),
	})
	.volatile((self) => ({
		isMoving: -1,
	}))
	.views((self) => ({
		get fullName() {
			if (self.bstn !== "") return self.bstn + ": " + self.name;
			return self.name;
		},
		jobMapList(domain) {
			let mp = new Map();
			for (let job of self.$processes.values()) {
				if (!isValidReference(() => job)) continue;
				/* eslint-disable no-loop-func */
				mp = new Map(
					(function*() {
						yield* mp;
						yield* job.jobMapList(domain);
					})()
				);
				/* eslint-enable no-loop-func */
			}
			return mp;
		},
		get agfFilterString() {
			if (!self.agfData || !self.agfData.data) return "";
			return self.agfData.hash === self.agfData.hashAccepted
				? "agf:ja"
				: self.agfData.hashAccepted === "deny"
				? "agf:nee"
				: "agf:?";
		},
		getRentalResourcesByClass() {
			const out = {};
			for (let rentalResource of self.$rentalResources.values()) {
				if (!isValidReference(() => rentalResource)) continue;
				if (!isValidReference(() => rentalResource._resclass)) continue;
				if (rentalResource.deleted) continue;
				const classid = rentalResource._resclass.id;
				if (!(classid in out)) out[classid] = new Map();
				out[classid].set(rentalResource.id, rentalResource);
			}
			return out;
		},
		oldFormat() {
			const q = JSON.parse(JSON.stringify(getSnapshot(self)));
			//get orders, and disposition as array
			q.processes = {};
			for (let process of self.$processes.values()) {
				if (!isValidReference(() => process)) continue;
				q.processes[process.id] = process.oldFormat();
			}
			q.contacts = {};
			for (let request of self.$contacts.values()) {
				if (!isValidReference(() => request)) continue;
				q.contacts[request.id] = request.oldFormat();
			}
			q.projectAttachments = {};
			for (let request of self.$projectAttachments.values()) {
				if (!isValidReference(() => request)) continue;
				q.projectAttachments[request.id] = request.oldFormat();
			}
			return pick(q, [
				"id",
				"name",
				"bstn",
				"costbase",
				"client",
				"contacts",
				"comment",
				"position",
				"processes",
				"updatedAt",
				"username",
				"editable",
				"moveable",
				"fixed",
				"projectAttachments"
			]);
		},

		get openRequests() {
			const out = {};
			for (let process of self.$processes.values()) {
				if (!isValidReference(() => process)) continue;
				if (process.deleted) continue;
				for (let job of process.$jobs.values()) {
					if (!isValidReference(() => job)) continue;
					if (job.deleted || job.openRequests.length === 0) continue;
					let key = moment(job.start)
						.startOf("day")
						.format("YYYY-MM-DD");
					for (let d of job.openRequests) {
						let row;
						let instance = 0;

						if (
							!isValidReference(() => d._requirement) ||
							!isValidReference(() => d._requirement._resclass)
						)
							continue;

						while (true) {
							row = d._requirement.id + "#" + instance;
							if (!(row in out)) {
								out[row] = {
									key: row,
									days: {},
									requirement: d._requirement,
								};
								break;
							}
							if (!(key in out[row].days)) break;
							instance++;
						}
						out[row].days[key] = d;
					}
				}
			}
			const outArr = Object.values(out);
			outArr.sort((a, b) =>
				a.requirement._resclass.human && !b.requirement._resclass.human
					? -1
					: !a.requirement._resclass.human && b.requirement._resclass.human
					? 1
					: a.requirement._resclass.name < b.requirement._resclass.name
					? -1
					: a.requirement._resclass.name > b.requirement._resclass.name
					? 1
					: a.requirement.name < b.requirement.name
					? -1
					: a.requirement.name > b.requirement.name
					? 1
					: 0
			);
			return outArr;
		},
		get resources() {
			//get map resource =>
			const out = {};
			const reps = new Set();
			for (let process of self.$processes.values()) {
				if (!isValidReference(() => process)) continue;
				if (process.deleted) continue;
				for (let job of process.$jobs.values()) {
					if (!isValidReference(() => job)) continue;
					if (
						job.deleted ||
						job.$deployments.size + job.$rentalDeployments.size === 0
					)
						continue;
					let key = moment(job.start)
						.startOf("day")
						.format("YYYY-MM-DD");
					for (let d of job.$deployments.values()) {
						if (!isValidReference(() => d) || d.deleted) continue;
						if (
							!isValidReference(() => d._resource) ||
							d._resource.deleted ||
							!isValidReference(() => d._resource._resclass) ||
							d._resource._resclass.deleted
						)
							continue;
						if (!(d._resource.id in out))
							out[d._resource.id] = {
								days: {},
								resource: d._resource,
								isRentalResource: false,
								isGroup: false,
							};
						if (!(key in out[d._resource.id].days))
							out[d._resource.id].days[key] = [];
						out[d._resource.id].days[key].push(job);

						if (!isValidReference(() => d._resGroup) || d._resGroup.deleted)
							continue;
						const group = d._resGroup;
						if (!(group.id in out)) {
							reps.add(group.id);
							out[group.id] = {
								days: {},
								resource: group,
								isRentalResource: false,
								isGroup: true,
								members: Array.from(group._members.values())
									.filter((x) => isValidReference(() => x) && !x.deleted)
									.sort((a, b) => {
										if (!a.resclass || !b.resclass) return 0;
										if (!a.resclass.human && b.resclass.human) return 1;
										if (a.resclass.human && !b.resclass.human) return -1;
										const cx = a.resclass.name.localeCompare(b.resclass.name);
										if (cx === 0) return a.name.localeCompare(b.name);
										return cx;
									})
									.map((x) => x.id),
							};
						}
						if (!(key in out[group.id].days)) out[group.id].days[key] = [];
						else if (out[group.id].days[key].find((x) => x.id === job.id))
							continue;
						out[group.id].days[key].push(job);
					}
					for (let d of job.$rentalDeployments.values()) {
						if (!isValidReference(() => d) || d.deleted) continue;
						if (
							!isValidReference(() => d._rentalResource) ||
							d._rentalResource.deleted ||
							!isValidReference(() => d._rentalResource._resclass) ||
							d._rentalResource._resclass.deleted
						)
							continue;
						if (!(d._rentalResource.id in out))
							out[d._rentalResource.id] = {
								days: {},
								resource: d._rentalResource,
								isRentalResource: true,
								isGroup: false,
							};
						if (!(key in out[d._rentalResource.id].days))
							out[d._rentalResource.id].days[key] = [];
						out[d._rentalResource.id].days[key].push(job);
					}
				}
			}
			for (let groupId of reps) {
				out[groupId].members = out[groupId].members.map((x) => out[x]);
			}
			const outArr = Object.values(out);
			outArr.sort((a, b) => {
				const aGroup = !a.isGroup ? 1 : 0;
				const bGroup = !b.isGroup ? 1 : 0;
				const aRes = !a.isRentalResource ? 1 : 0;
				const bRes = !b.isRentalResource ? 1 : 0;
				const aClassName = a.isGroup ? "" : a.resource._resclass.name;
				const bClassName = b.isGroup ? "" : b.resource._resclass.name;
				const aName = a.resource.name;
				const bName = b.resource.name;

				return aGroup > bGroup
					? -1
					: aGroup < bGroup
					? 1
					: aRes > bRes
					? -1
					: aRes < bRes
					? 1
					: aClassName < bClassName
					? -1
					: aClassName > bClassName
					? 1
					: aName < bName
					? -1
					: aName > bName
					? 1
					: 0;
			});

			return outArr;
		},
		get start() {
			let out = null;
			for (let p of self.$processes.values()) {
				if (!isValidReference(() => p) || p.deleted) continue;
				if (p.start !== null && (out === null || out > p.start)) out = p.start;
			}
			return out;
		},
		get end() {
			let out = null;
			for (let p of self.$processes.values()) {
				if (!isValidReference(() => p) || p.deleted) continue;
				if (p.end !== null && (out === null || out < p.end)) out = p.end;
			}
			return out;
		},
		get days() {
			let out = new Map();
			
			for (let process of self.$processes.values()) {
				if (!isValidReference(() => process) || process.deleted) continue;
				for (let job of process.$jobs.values()) {
					if (!isValidReference(() => job) || job.deleted) continue;
					let start = moment(job.start).startOf("day");
					let key = start.format("YYYY-MM-DD");

					if (out.has(key)) continue;

					out.set(key, 0);
				}
			}

			return out;
		},
		get unifiedByDayWithProcesses() {
			let upperout = new Map();
			let main_out = new Map();
			upperout.set("main", {});
			
			for (let process of Array.from(self.$processes.values()).sort((a, b) =>
				!isValidReference(() => a) || !isValidReference(() => b)
					? 0
					: a.start < b.start
					? -1
					: a.start > b.start
					? 1
					: 0
			)) {
				if (!isValidReference(() => process) || process.deleted) continue;
				let processId = process.id;
				let process_out = new Map();
				for (let job of process.$jobs.values()) {
					if (!isValidReference(() => job) || job.deleted) continue;
					let start = moment(job.start).startOf("day");
					let key = start.format("YYYY-MM-DD");
					let end = moment(job.start).endOf("day");

					if (!main_out.has(key)) {
						main_out.set(key, {
							start: start.toDate(),
							end: end.toDate(),
							processes: new Map(),
							isNight: job.isNight,
						});
					}
					main_out.get(key).processes.set(processId, true);
					main_out.get(key).isNight = main_out.get(key).isNight || job.isNight;

					if (process_out.has(key)) {
						process_out.get(key).isNight =
							process_out.get(key).isNight || job.isNight;
						continue;
					}

					process_out.set(key, {
						isNight: job.isNight,
						start: start.toDate(),
						end: end.toDate(),
					});
				}
				upperout.set(processId, {
					name: process.name,
					processId: processId,
					data: process_out,
				});
			}
			/* eslint-enable no-unused-vars */

			upperout.set("main", {
				name: self.bstn + (self.bstn.length ? ": " : "") + self.name,
				processId: "main",
				data: main_out,
			});
			return upperout;
		},
		get unifiedByDay() {
			let upperout = new Map();
			let out = new Map();
			
			for (let process of self.$processes.values()) {
				if (!isValidReference(() => process) || process.deleted) continue;
				for (let job of process.$jobs.values()) {
					if (!isValidReference(() => job) || job.deleted) continue;
					let start = moment(job.start).startOf("day");
					let key = start.format("YYYY-MM-DD");

					if (out.has(key)) {
						out.get(key).isNight = out.get(key).isNight || job.isNight;
						continue;
					}

					let end = moment(job.start).endOf("day");

					out.set(key, {
						start: start.toDate(),
						end: end.toDate(),
						isNight: job.isNight,
					});
				}
			}
			/* eslint-enable no-unused-vars */

			upperout.set("main", {
				name: self.bstn + (self.bstn.length ? ": " : "") + self.name,
				processId: "main",
				data: out,
			});
			return upperout;
		},
		getJobsAfter(start) {
			return Array.from(self.$processes.values())
				.filter(
					(process) => isValidReference(() => process) && !process.deleted
				)
				.reduce(
					(acc, process) =>
						acc.set(
							process.id,
							Array.from(process.$jobs.values())
								.filter(
									(job) =>
										isValidReference(() => job) &&
										job.start >= start &&
										!job.deleted
								)
								.map((x) => x.id)
								.reduce((acc2, jid) => acc2.set(jid, true), new Map())
						),
					new Map()
				);
		},
	}))
	.actions((self) => ({
		agfState(state) {
			const agfData = JSON.parse(JSON.stringify(self.agfData));
			agfData.hashAccepted = state ? agfData.hash : "deny";
			agfData.nameAccepted = localStorage.getItem("binfra_username");
			agfData.dateAccepted = moment().format("YYYY-MM-DD");
			self.agfData = agfData;
			self.updatedAt = new Date();
		},
		setFixed(fixed) {
			self.fixed = fixed;
			self.updatedAt = new Date();
		},
		registerProcess(id) {
			self.$processes.set(id, id);
		},
		registerContact(id) {
			self.$contacts.set(id, id);
		},
		registerProjectAttachment(id) {
			self.$projectAttachments.set(id, id);
		},
		rentalDispose(jobs, operations) {
			for (let job of jobs) {
				const removeList = new Set();
				for (let [rentalResource, operation] of operations) {
					if (operation === "ADD") {
						getRoot(self).projects.updateRentalDeployments([
							{
								id: uuid.v4(),
								_project: self.id,
								_job: job.id,
								_rentalResource: rentalResource,
							},
						]);
					} else if (operation === "REMOVE") {
						removeList.add(rentalResource);
					}
				}
				if (removeList.size > 0) job.removeRentalResources(removeList);
			}
		},
		registerRentalResource(id) {
			self.$rentalResources.set(id, id);
		},
		setMoving(x) {
			self.isMoving = x;
		},
		delete() {
			self.deleted = true;
			self.updatedAt = new Date();
			getRoot(self).resources.triggerCollisionRecalculation();
		},
		updateRentalResource(id, name, supplier) {
			if (self.$rentalResources.has(id)) {
				const rentalResource = self.$rentalResources.get(id);
				if (!isValidReference(() => rentalResource)) return;
				rentalResource.update(name, supplier);
			}
		},
	}));

const ProjectStore = types
	.model("ProjectStore", {
		projects: types.map(Project),
		processes: types.map(ProjectProcess),
		jobs: types.map(ProjectJob),
		contacts: types.map(Contact),
		requests: types.map(Request),
		rentalResources: types.map(RentalResource),
		rentalDeployments: types.map(RentalDeployment),
		projectAttachments: types.map(ProjectAttachment)
	})
	.views((self) => ({
		getProjectsBetween(v) {
			let a = Array.from(self.projects.values());
			let x = a.filter(
				(p) =>
					!p.deleted && v.start <= p.end && v.end >= p.start && p.isMoving < 0
			);
			let y = a.filter((p) => !p.deleted && p.isMoving > -1);
			switch (getRoot(self).ui.sortMethod) {
				case "dateAscending":
					x.sort((a, b) =>
						a.start < b.start ? -1 : a.start > b.start ? 1 : 0
					);
					break;
				case "dateDescending":
					x.sort((a, b) =>
						a.start < b.start ? 1 : a.start > b.start ? -1 : 0
					);
					break;
				case "fixedAscending":
					x.sort((a, b) =>
						!a.fixed && b.fixed ? -1 : a.fixed && !b.fixed ? 1 : a.start < b.start ? -1 : a.start > b.start ? 1 : 0
					);
					break;
				case "fixedDescending":
					x.sort((a, b) =>
						!a.fixed && b.fixed ? 1 : a.fixed && !b.fixed ? -1 : a.start < b.start ? -1 : a.start > b.start ? 1 : 0
					);
					break;
				case "numberAscending":
					x.sort((a, b) => a.bstn.localeCompare(b.bstn));
					break;
				case "numberDescending":
					x.sort((a, b) => b.bstn.localeCompare(a.bstn));
					break;
				case "nameAscending":
					x.sort((a, b) => a.name.localeCompare(b.name));
					break;
				case "nameDescending":
					x.sort((a, b) => b.name.localeCompare(a.name));
					break;
				default:
					break;
			}

			if (!y.length) return x;
			x.splice(y[0].isMoving, 0, y[0]);
			return x;
		},
		getJobsBetween(v) {
			let a = Array.from(self.jobs.values());
			let x = a.filter(
				(p) => !p.deleted && p.process && v.start <= p.end && v.end >= p.start
			);
			x.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0));
			return x;
		},
		getJobsByDates(dates) {
			const out = [];
			dates.sort();
			let start = strToDate(dates[0]);
			let end = strToDate(dates[dates.length - 1], 1);
			for (let project of self.projects.values()) {
				if (project.deleted || start > project.end || end < project.start)
					continue;
				for (let process of project.$processes.values()) {
					if (!isValidReference(() => process) || process.deleted) continue;
					for (let job of process.$jobs.values()) {
						if (!isValidReference(() => job) || job.deleted) continue;
						const key = moment(job.start).format("YYYY-MM-DD");
						if (!dates.includes(key)) continue;
						out.push(job);
					}
				}
			}
			out.sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0));
			return out;
		},
		getJobs(targets) {
			const jobs = [];
			for (let targetstring of targets) {
				if (typeof targetstring !== "string") {
					jobs.push(targetstring);
					continue;
				}
				const [projectId, processId, dayId] = targetstring.split("#");
				const project = self.projects.get(projectId);
				if (project.deleted) continue;
				for (let process of project.$processes.values()) {
					if (
						!isValidReference(() => process) ||
						process.deleted ||
						(process.id !== processId && processId !== "*")
					)
						continue;
					for (let job of process.$jobs.values()) {
						if (!isValidReference(() => job) || job.deleted) continue;
						const start = moment(job.start)
							.startOf("day")
							.format("YYYY-MM-DD");
						if (start !== dayId && dayId !== "*") continue;
						jobs.push(job);
					}
				}
			}
			return jobs;
		},
	}))
	.actions((self) => ({
		move(moveList) {
			for (let [job, diff] of moveList) {
				job.move(diff);
			}
		},
		requestDispose(operations) {
			for (let o of operations) {
				for (let job of o.jobs) {
					job.updateRequests(o.requests);
				}
			}
		},
		saveProjectEdit(data, orders, dispositions, requests) {
			const project = omit(data, ["processes", "contacts","projectAttachments"]);
			self.updateProjects([project]);

			for (let contact of Object.values(data.contacts)) {
				const requestX = omit(contact, ["person"]);
				requestX._project = data.id;
				requestX._person = contact.person;
				self.updateContacts([requestX]);
			}

			for (let contact of Object.values(data.projectAttachments)) {
				const requestX = omit(contact, []);
				requestX._project = data.id;
				self.updateProjectAttachments([requestX]);
			}

			//console.log(project);
			const deletedJobs = new Set();

			for (let process of Object.values(data.processes)) {
				const processX = omit(process, ["processes", "template"]);
				processX._template = process.template;
				processX._project = data.id;
				self.updateProcesses([processX]);

				for (let job of Object.values(process.jobs)) {
					const jobX = omit(job, ["disposition", "orders", "requests"]);
					jobX._project = data.id;
					jobX._process = process.id;
					if (processX.deleted) jobX.deleted = true;
					if (jobX.deleted) deletedJobs.add(jobX.id);
					self.updateJobs([jobX]);
				}
			}
			const oS = getRoot(self).orders;
			for (let order of orders.values()) {
				const orderX = omit(order, ["job", "material", "supplier"]);
				orderX._job = order.job;
				orderX._project = data.id;
				orderX._material = order.material;
				orderX._supplier = order.supplier;
				if (deletedJobs.has(orderX._job)) orderX.deleted = true;
				oS.updateOrders([orderX]);
			}
			for (let disposition of dispositions.values()) {
				const orderX = omit(disposition, ["job", "type", "supplier"]);
				orderX._job = disposition.job;
				orderX._project = data.id;
				orderX._type = disposition.type;
				orderX._supplier = disposition.supplier;
				if (deletedJobs.has(orderX._job)) orderX.deleted = true;
				oS.updateDisposition([orderX]);
			}
			for (let request of requests.values()) {
				const orderX = omit(request, ["requirement", "job", "project"]);
				orderX._job = request.job;
				orderX._project = data.id;
				orderX._requirement = request.requirement;
				if (deletedJobs.has(orderX._job)) orderX.deleted = true;
				self.updateRequests([orderX]);
			}
		},
		update(collection, data, registerRefs) {
			for (let d of data) {
				if (collection.has(d.id)) {
					const originalData = collection.get(d.id);
					if (originalData.updatedAt >= new Date(d.updatedAt)) continue;
					d = Object.assign(
						JSON.parse(JSON.stringify(getSnapshot(originalData))),
						d
					);
				}
				try {
					collection.set(d.id, d);
					if (registerRefs) collection.get(d.id).registerReferences();
				} catch (e) {
					console.log(e);
				}
			}
		},
		collectChanges(lastSave) {
			const collections = [
				"projects",
				"processes",
				"jobs",
				"contacts",
				"requests",
				"rentalDeployments",
				"rentalResources",
				"projectAttachments"
			];
			const out = {};
			for (let collectionName of collections) {
				const collection = self[collectionName];
				for (let data of collection.values()) {
					if (data.updatedAt < lastSave) continue;
					if (!(collectionName in out)) out[collectionName] = [];
					out[collectionName].push(
						omitBy(
							JSON.parse(JSON.stringify(getSnapshot(data))),
							(value, key) => key.startsWith("$")
						)
					);
				}
			}
			return out;
		},
		updateProjects(data) {
			self.update(self.projects, data, false);
			getRoot(self).resources.triggerCollisionRecalculation();
		},
		updateProcesses(data) {
			self.update(self.processes, data, true);
			getRoot(self).resources.triggerCollisionRecalculation();
		},
		updateRentalResources(data) {
			self.update(self.rentalResources, data, true);
		},
		updateRentalDeployments(data) {
			self.update(self.rentalDeployments, data, true);
		},
		updateJobs(data) {
			self.update(self.jobs, data, true);
			getRoot(self).resources.triggerCollisionRecalculation();
		},
		updateContacts(data) {
			self.update(self.contacts, data, true);
		},
		updateProjectAttachments(data) {
			self.update(self.projectAttachments, data, true);
		},
		updateRequests(data) {
			self.update(self.requests, data, true);
		},
	}));

export default ProjectStore;
