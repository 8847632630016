import React from "react";
import { observer, inject } from "mobx-react";
import { isValidReference } from "mobx-state-tree";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import ClusterDetail from "./clusterDetail";
import DraggableCore from "react-draggable";

class ClusterBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pos: 0,
		};
	}

	onStart() {
		this.props.setDragMode(true);
	}

	onStop(x) {
		const y = x.node.getBoundingClientRect();
		let last = false;
		let prelast = false;
		for (let line of document.getElementsByClassName("detaildroptarget")) {
			prelast = last;
			last = [line.dataset.resource, line.getBoundingClientRect().y];
			if (last[1] >= y.y) break;
		}
		let target;
		if (prelast) {
			const targetX =
				Math.abs(y.y - last[1]) < Math.abs(y.y - prelast[1]) ? last : prelast;
			//console.log(Math.abs(y.y - targetX[1]), y.height);
			if (Math.abs(y.y - targetX[1]) > y.height) target = false;
			else target = targetX[0];
		} else {
			if (Math.abs(y.y - last[1]) > y.height) target = false;
			else target = last[0];
		}

		if (target && target !== this.props.resid) {
			//console.log("MOVE");
			this.props.app.resources.handleVerticalMove(
				this.props.deployments,
				target,
				this.props.date
			);
		}
		this.props.setDragMode(false);
		this.setState({
			pos: 0,
		});
	}

	onDrag(y) {
		this.setState({
			pos: y,
		});
	}

	static getDeploymentData(deployments, showResources) {
		const orders = [];
		const disposition = [];
		const comments = [];
		const resources = [];
		let startTime = Infinity;
		let endTime = -Infinity;
		const contacts = {};
		const jobs = [];

		for (let d of deployments) {
			if (d.deleted || d.job.deleted) continue;
			const job = d.job;

			jobs.push(job);
			if (job.comment !== "") comments.push(job.comment);
			startTime = startTime < job.start ? startTime : job.start;
			endTime = endTime > job.end ? endTime : job.end;
			if (showResources) {
				for (let deployment of job.$deployments.values()) {
					if (
						!isValidReference(() => deployment) ||
						deployment.deleted ||
						!isValidReference(() => deployment._resource) ||
						deployment._resource.deleted
					)
						continue;
					resources[deployment._resource.id] = {
						icon: deployment._resource._resclass.human
							? "user"
							: "picon picon-excavator",
						name: deployment._resource.name,
					};
				}

				// resources.sort((a, b) => a.name.localeCompare(b.name));
			}
			for (let order of job.$orders.values()) {
				if (!isValidReference(() => order) || order.deleted) continue;
				orders.push({
					material: order.material.safeShortname,
					supplier: order.supplier.safeShortname,
					unit: order.material.unit,
					amount: order.amount,
				});
			}
			for (let dispo of job.$disposition.values()) {
				if (!isValidReference(() => dispo) || dispo.deleted) continue;
				disposition.push({
					type: dispo.type.name,
					supplier: dispo.supplier.safeShortname,
					amount: dispo.amount,
				});
			}
			for (let contact of job.project.$contacts.values()) {
				if (!isValidReference(() => contact) || contact.deleted) continue;
				contacts[contact.person.name + "-" + contact.function] = {
					name: contact.person.name,
					function: contact.function,
				};
			}
		}

		return {
			name:
				deployments[0].job.project.bstn +
				(deployments[0].job.project.bstn !== "" ? ": " : "") +
				deployments[0].job.project.name,
			costBase: deployments[0].job.project.costBase,
			bstn: deployments[0].job.project.bstn,
			start: moment(startTime).format("HH:mm"),
			end: moment(endTime).format("HH:mm"),
			orders,
			resources: Object.values(resources),
			disposition,
			comments,
			contacts: Object.values(contacts),
			jobs,
		};
	}

	render() {
		const d = ClusterBox.getDeploymentData(
			this.props.deployments,
			this.props.app.ui.modules.has("DETAILS_SHOWRESOURCES")
		);

		const dispo = [
			...new Set(
				d.disposition.map((o) => {
					return this.props.t("detailBox.disposition", o);
				})
			),
		];
		const orders = [
			...new Set(
				d.orders.map((o) => {
					return this.props.t("detailBox.order", o);
				})
			),
		];
		const contacts = [
			...new Set(
				d.contacts.map((o) => {
					return o["function"] ? this.props.t("detailBox.contact", o) : o.name;
				})
			),
		];
		contacts.sort(function(a, b){
			if(a < b) { return -1; }
			if(a > b) { return 1; }
			return 0;
		});
		console.log(d.resources,'resources');
		const comments = [...new Set(d.comments)];
		let dc = 0;
		const content = [].concat(
			comments.map((x) => <ClusterDetail icon="comment" key={dc++} text={x} />),
			orders.map((x) => <ClusterDetail icon="cubes" key={dc++} text={x} />),
			dispo.map((x) => <ClusterDetail icon="truck" key={dc++} text={x} />),
			contacts.map((x) => <ClusterDetail icon="user" key={dc++} text={x} />),
			d.resources.map((x) => (
				<ClusterDetail icon={x.icon} key={dc++} text={x.name} />
			))
		);

		let jobSelector,
			resourceSelector = () => this.props.startResourceEditor(d.jobs);
		if (d.jobs.length === 1) {
			jobSelector = () => this.props.startJobEditModal(d.jobs[0]);
		} else {
			jobSelector = () =>
				this.props.startJobChooserModal(d.jobs, this.props.startJobEditModal);
		}

		return (
			<div
				className={"cluster_box_wrapper"}
				style={{
					cursor: "pointer",
					position: "absolute",
					zIndex: this.state.pos !== 0 ? 2 : 1,
					left: this.props.left + 1,
					width: this.props.width - 1,
					height:
						(this.state.pos === 0 ? this.props.size : this.props.boxHeight) - 1,
					bottom: 0,
				}}
			>
				<DraggableCore
					axis="none"
					disabled={!this.props.app.ui.rights.has("PLAN_RESOURCES")}
					onDrag={(x, y) => this.onDrag(y.y)}
					onStart={(x, y) => this.onStart(y)}
					onStop={(x, y) => this.onStop(y)}
					//defaultPosition={{ x: pos, y: 0 }}
					position={{ y: this.state.pos, x: 0 }}
				>
					<div
						className="cluster_box xls_box"
						data-start={this.props.date}
						data-size={this.props.xsize}
					>
						<div className="itemBoxTop">
							<div className="itemBoxTopTime xls_start">{d.start}</div>
							<div className="itemBoxTopName xls_title" title={d.name}>
								{d.name}
							</div>
						</div>
						<div className="itemBoxCenter xls_content">{content}</div>
						<div className="itemBoxBottomGrouper">
							<div className="itemBoxBottomTime xls_end">{d.end}</div>

							<div className="itemBoxBottomButtons">
								
									<React.Fragment>
										<div className="itemBoxBottom" onClick={jobSelector}>
											<Icon name="pencil" />
										</div>
										<div className="itemBoxBottom" onClick={resourceSelector}>
											<i className="picon picon-excavator" />
										</div>
									</React.Fragment>
								
							</div>
						</div>
					</div>
				</DraggableCore>
			</div>
		);
	}
}

export default withTranslation()(inject("app")(observer(ClusterBox)));
