import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {Popup, Icon} from "semantic-ui-react";

const Answer = (props) => {
	const { t } = useTranslation();
	let answerText = "";
	if (props.answer._question.type !== "TIMESPAN")
		answerText = props.answer.answer;
	else {
		const x = props.answer.answer.split(",");
		let startTime = "";
		let endTime = "";
		let diff = "";
		let invalid = false;
		if (x.length === 2) {
			startTime = x[0];
			endTime = x[1];
		} else {
			invalid = true;
		}
		if (startTime.length && endTime.length) {
			let sT = moment(startTime, "HH:mm");
			let eT = moment(endTime, "HH:mm");
			if (eT < sT) eT.add(1, "day");
			diff = " (" + moment.utc(eT.diff(sT)).format("HH:mm") + " h)";
		}
		answerText = invalid ? "" : startTime + "–" + endTime + diff;
	}
	if (!answerText.length) return "";
	return (
		<div className="answerWrapper">
			{answerText}
			<Popup
				content={t("questions.reports.answeredByAt", {
					writer: props.answer._answeredBy.name,
					date: moment(props.answer.answeredAt).format("DD.MM.YYYY HH:mm"),
				})}
				position='left center'
				size="small"
				trigger={<Icon className="qQuestionInfoIcon no-print" size="tiny" circular inverted name="info" />}
			/>
		</div>
	);
};

export default Answer;
